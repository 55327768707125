import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	TextInput,
	Button,
	Group,
	Box,
	PasswordInput,
	Anchor,
	SimpleGrid,
	rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { toast } from "react-toastify";
import zxcvbn from "zxcvbn";
import AuthContext from "../services/Auth.context.js";
import "../App.css";

import { IconAt, IconPassword, IconUser } from "@tabler/icons-react";

import AuthService from "../services/auth.service";
import {
	fetchFromAPI,
	checkDatabaseStatus,
} from "../services/fetch.service.js";
import { updateToast } from "../services/toast.service.js";

function LoginPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const { from } = location.state || { from: { pathname: "/" } };
	const { setToken } = useContext(AuthContext);

	useEffect(() => {
		checkDatabaseStatus().then((databaseStatus) => {
			if (!databaseStatus) {
				// Redirect to the Web Site Down Page
				navigate("/web-site-down");
			}
		});

		const token = AuthService.getCookie("jwt");
		if (token) {
			// Validate the token on the server
			(async () => {
				const decodedToken = await AuthService.validateToken(token);
				if (decodedToken) {
					// Token exists, redirect to requested page
					navigate(from.pathname);
				}
			})();
		}
	}, [navigate, from.pathname]);

	// Create a form using the useForm hook
	const form = useForm({
		initialValues: {
			name: "",
			email: "",
			password: "",
			confirmPassword: "",
		},
		validateInputOnBlur: true,
		validate: {
			// Name Validation
			name: (value) => {
				// If the form type is register
				if (formType === "register") {
					// Check to see if the name is empty
					if (!value) {
						return "Name is required";
					}
					// Check to see if the name is less than 2 characters
					else if (value.length < 2) {
						return "Name must be at least 2 characters";
					}
					// Check to see if the name is more than 50 characters
					else if (value.length > 50) {
						return "Name must be less than 50 characters";
					}
				}
			},

			// Email Validation
			email: (value) => {
				// Check to see if the email is empty
				if (!value) {
					return "Email is required";
				}
				if (formType === "register") {
					// Check to see if the email is less than 5 characters
					if (value.length < 5) {
						return "Email must be at least 5 characters";
					}
					// Check to see if the email is more than 50 characters
					else if (value.length > 50) {
						return "Email must be less than 50 characters";
					}
					// Check to see if email matches regex
					else if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
						return "Email must be valid";
					}
				}
			},

			// Password Validation
			password: (value) => {
				// If the form type is login or register
				if (formType === "login" || formType === "register") {
					// Check to see if the password is empty
					if (!value) {
						return "Password is required";
					}
				}
				// If the form type is register
				if (formType === "register") {
					// Check to see if the password is less than 8 characters
					if (value.length < 8) {
						return "Password must be at least 8 characters";
					}
					// Check to see if the password is more than 256 characters
					else if (value.length > 256) {
						return "Password must be less than 256 characters";
					}
					// Use zxcvbn to check the password strength
					else if (zxcvbn(value).score < 3) {
						return "Password is too weak";
					}
					// Check to see if the password contains at least 1 lowercase letter
					else if (!value.match(/[a-z]/)) {
						return "Password must contain at least 1 lowercase letter";
					}
					// Check to see if the password contains at least 1 uppercase letter
					else if (!value.match(/[A-Z]/)) {
						return "Password must contain at least 1 uppercase letter";
					}
					// Check to see if the password contains at least 1 number
					else if (!value.match(/[0-9]/)) {
						return "Password must contain at least 1 number";
					}
					// Check to see if the password contains at least 1 special character
					else if (
						!value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/)
					) {
						return "Password must contain at least 1 special character";
					}
				}
			},

			// Confirm Password Validation
			confirmPassword: (value) => {
				// If the form type is register
				if (formType === "register") {
					// Check to see if the confirm password matches the password
					if (value !== form.values.password) {
						return "Confirm Password must match Password";
					}
				}
			},
		},
	});

	const [formType, setFormType] = useState("login");
	const [formTitle, setFormTitle] = useState("Login");
	const [formDescription, setFormDescription] = useState(
		"Enter your email and password below to sign in"
	);
	const [formFooterLink1, setFormFooterLink1] = useState(
		"Forget your password?"
	);
	const [formFooterLink2, setFormFooterLink2] = useState(
		"Don't have an account? Sign up here"
	);
	const [formSubmitButtonText, setFormSubmitButtonText] = useState("Login");
	const [notificationAttempt, setNotificationAttempt] = useState(
		"Attempting to Login"
	);

	// If the user wants to switch to the forgot_password form
	const handleForgotPasswordFormClick = () => {
		setFormType("forgot_password");
		setFormTitle("Forgot Password");
		setFormDescription(
			"Enter your email below and we will send you a link to reset your password"
		);
		setFormSubmitButtonText("Send Reset Link");
		setFormFooterLink1("Already have an account?");
		setFormFooterLink2("Don't have an account? Sign up here");
		setNotificationAttempt("Attempting to Send Reset Link");
		form.clearErrors();
	};

	// If the user wants to switch to the register form
	const handleRegisterFormClick = () => {
		setFormType("register");
		setFormTitle("Register");
		setFormDescription(
			"Enter your name, email and password below to create an account"
		);
		setFormSubmitButtonText("Register Account");
		setFormFooterLink1("Already have an account?");
		setFormFooterLink2("Forgot Password?");
		setNotificationAttempt("Attempting to Register");
		form.clearErrors();
	};

	// If the user wants to switch to the login form
	const handleLoginFormClick = () => {
		setFormType("login");
		setFormTitle("Login");
		setFormDescription("Enter your email and password below to sign in");
		setFormSubmitButtonText("Login");
		setFormFooterLink1("Forgot Password?");
		setFormFooterLink2("Don't have an account? Sign up here");
		setNotificationAttempt("Attempting to Login");
		form.clearErrors();
		navigate("/login");
	};

	const api_login = async (values) => {
		const API_PATH = "/login";
		try {
			const body = JSON.stringify({
				email: values.email,
				password: values.password,
			});
			const data = await fetchFromAPI(API_PATH, {
				method: "POST",
				body: body,
			});
			if (data) {
				return data;
			} else {
				throw new Error("No data returned from API");
			}
		} catch (error) {
			throw error;
		}
	};

	// Handle form submission
	const handleFormSubmit = async (values, formType) => {
		const thisToast = toast.info(notificationAttempt, {
			autoClose: 5000,
		});

		// Check to see the form is valid
		if (!form.isValid) {
			return;
		}

		try {
			// If the form type is login send the email and password to the server
			if (formType === "login") {
				const resp_login = await api_login(values);

				// Check if the login was successful
				if (!resp_login || resp_login.status !== "success") {
					form.setFieldValue("password", "");
					setToken(null);
					throw new Error(resp_login.message || "Login Failed");
				}

				if (resp_login && resp_login.status === "success") {
					// Extract the JWT token from the response
					const token = resp_login.token;

					// Extract the expiration time of the token
					const expirationTime = new Date(
						resp_login.expiration_time * 1000
					);

					// Set the token as a cookie with an expiration time
					document.cookie = `jwt=${token}; expires=${expirationTime.toUTCString()}; path=/`;

					// Update the toast
					updateToast(thisToast, "Login Successful", "success");
					setToken(token);
					navigate(from.pathname);
				}
			}

			// If the form type is register send the name, email and password to the server
			else if (formType === "register") {
				const API_PATH = "/register";
				try {
					const data = await fetchFromAPI(API_PATH, {
						method: "POST",
						body: JSON.stringify({
							name: values.name,
							email: values.email,
							password: values.password,
						}),
					});

					if (data.status === "success") {
						// Navigate to the login page
						form.setFieldValue("name", "");
						form.setFieldValue("email", "");
						form.setFieldValue("password", "");
						form.setFieldValue("confirmPassword", "");
						updateToast(
							thisToast,
							"Registration Successful",
							"success"
						);
						handleLoginFormClick();
					} else if (data.status === "none") {
						if (data.message === "email already exists") {
							updateToast(
								thisToast,
								"Password not changed. Email already exists.",
								"warning"
							);
							form.setFieldValue("password", "");
							form.setFieldValue("confirmPassword", "");
							handleLoginFormClick();
						}
					} else {
						// Clear the password and confirm password fields
						form.setFieldValue("password", "");
						form.setFieldValue("confirmPassword", "");
						updateToast(thisToast, "Registration Failed", "error");
					}
				} catch (error) {
					form.setFieldValue("password", "");
					form.setFieldValue("confirmPassword", "");
					updateToast(thisToast, "Registration Failed", "error");
				}
			}

			// If the form type is forgot_password send the email to the server
			else if (formType === "forgot_password") {
				const API_PATH = "/forgot_password";
				try {
					const data = await fetchFromAPI(API_PATH, {
						method: "POST",
						body: JSON.stringify({
							email: values.email,
						}),
					});
					if (data.status === "success") {
						// Set state and Navigate to the login page
						updateToast(thisToast, "Reset Link Sent", "success");
						handleLoginFormClick();
					} else {
						form.setFieldValue("email", "");
						updateToast(thisToast, "Reset Link Failed", "error");
					}
				} catch (error) {
					form.setFieldValue("email", "");
					updateToast(thisToast, "Reset Link Failed", "error");
				}
			}
		} catch (error) {
			updateToast(thisToast, error.message, "error");
		}
	};

	// Change the document title based on the form type
	useEffect(() => {
		if (formType === "login") {
			document.title = "MooseBoxx | Login";
		} else if (formType === "register") {
			document.title = "MooseBoxx | Register";
		} else if (formType === "forgot_password") {
			document.title = "MooseBoxx | Forgot Password";
		} else {
			document.title = "MooseBoxx";
		}
	}, [formType]);

	return (
		<Box maw={400} mx="auto">
			<h1>{formTitle}</h1>
			<p>{formDescription}</p>
			<form
				onSubmit={form.onSubmit(() =>
					handleFormSubmit(form.values, formType)
				)}
			>
				{formType === "register" && (
					<TextInput
						withAsterisk
						leftSection={
							<IconUser
								style={{ width: rem(16), height: rem(16) }}
							/>
						}
						label="Name"
						placeholder="Name"
						{...form.getInputProps("name")}
					/>
				)}

				<TextInput
					withAsterisk
					leftSection={
						<IconAt style={{ width: rem(16), height: rem(16) }} />
					}
					label="Email"
					id="email"
					placeholder="your@email.com"
					{...form.getInputProps("email")}
				/>

				{(formType === "login" || formType === "register") && (
					<PasswordInput
						withAsterisk
						leftSection={
							<IconPassword
								style={{ width: rem(16), height: rem(16) }}
							/>
						}
						label="Password"
						id="password"
						placeholder="Password"
						{...form.getInputProps("password")}
					/>
				)}

				{formType === "register" && (
					<PasswordInput
						withAsterisk
						leftSection={
							<IconPassword
								style={{ width: rem(16), height: rem(16) }}
							/>
						}
						label="Confirm Password"
						id="confirmPassword"
						placeholder="Confirm Password"
						{...form.getInputProps("confirmPassword")}
					/>
				)}

				{formType === "login" && (
					<Box mt="1rem">
						<SimpleGrid cols={1} verticalSpacing={"xs"}>
							<Anchor
								href="#"
								onClick={handleForgotPasswordFormClick}
							>
								{formFooterLink1}
							</Anchor>

							<Anchor href="#" onClick={handleRegisterFormClick}>
								{formFooterLink2}
							</Anchor>
						</SimpleGrid>
					</Box>
				)}

				{formType === "register" && (
					<Box mt="1rem">
						<SimpleGrid cols={1} verticalSpacing={"xs"}>
							<Anchor href="#" onClick={handleLoginFormClick}>
								{formFooterLink1}
							</Anchor>
							<Anchor
								href="#"
								onClick={handleForgotPasswordFormClick}
							>
								{formFooterLink2}
							</Anchor>
						</SimpleGrid>
					</Box>
				)}

				{formType === "forgot_password" && (
					<Box mt="1rem">
						<SimpleGrid cols={1} verticalSpacing={"xs"}>
							<Anchor href="#" onClick={handleLoginFormClick}>
								{formFooterLink1}
							</Anchor>
							<Anchor href="#" onClick={handleRegisterFormClick}>
								{formFooterLink2}
							</Anchor>
						</SimpleGrid>
					</Box>
				)}

				<Group justify="center" mt="md">
					<Button type="submit" variant="gradient">
						{formSubmitButtonText}
					</Button>
				</Group>
			</form>
		</Box>
	);
}

export default LoginPage;
