import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
	AppShell,
	Burger,
	Group,
	Button,
	Image,
	NavLink,
	rem,
} from "@mantine/core";
import {
	IconKey,
	IconMovie,
	IconCreditCardPay,
	IconLogout,
	IconPhoto,
} from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";
import AuthContext from "./services/Auth.context";

import "./App.css";

import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import TransactionsPage from "./pages/TransactionsPage";
import PlexToolsPage from "./pages/PlexToolsPage";
import PlexPage from "./pages/PlexPage";
import WebSiteDownPage from "./pages/WebSiteDownPage";
import Page404 from "./pages/404Page";

import AuthService from "./services/auth.service";
import PasswordResetPage from "./pages/PasswordResetPage";

function App() {
	const [token, setToken] = useState(AuthService.getCookie("jwt"));
	const [authLabel, setAuthLabel] = useState("Login");
	const [role, setRole] = useState("user");

	const navigate = useNavigate();
	const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
	const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

	// Nav Elements
	const navTitle = "Pages and Tools";

	// If user clicks the logout button, this function will be called
	const handleLogout = async () => {
		const currentToken = AuthService.getCookie("jwt");
		if (currentToken) {
			const response = await AuthService.logout(currentToken);
			if (response === null || response.status === "success") {
				AuthService.removeToken("jwt");
				navigate("/");
				toggleMobile();
				setToken(null);
				setAuthLabel("Login");
			}
		}
	};

	useEffect(() => {
		// This effect will run whenever the token changes
		const validateToken = async () => {
			if (token) {
				const decodedToken = await AuthService.validateToken(token);
				setAuthLabel(`${decodedToken.name}'s Profile`);
				setRole(decodedToken.role);
			} else {
				setToken(null);
				setAuthLabel("Login");
				setRole("user");
			}
		};
		validateToken();
	}, [token]);

	return (
		<div className="App">
			<AuthContext.Provider value={{ setToken }}>
				<AppShell
					header={{ height: 60 }}
					navbar={{
						width: 300,
						breakpoint: "sm",
						collapsed: {
							mobile: !mobileOpened,
							desktop: !desktopOpened,
						},
					}}
					footer={{
						height: 40,
						styles: { textAlign: "center" },
					}}
					padding="md"
				>
					<AppShell.Header>
						<Group h="100%" px="md">
							<Burger
								opened={mobileOpened}
								onClick={toggleMobile}
								hiddenFrom="sm"
								size="sm"
							/>
							<Burger
								opened={desktopOpened}
								onClick={toggleDesktop}
								visibleFrom="sm"
								size="sm"
							/>
							<Image
								radius="md"
								h={50}
								w="auto"
								fit="contain"
								src="web-app-manifest-512x512.png"
								href="/"
								onClick={() => {
									navigate("/");
								}}
							/>
						</Group>
					</AppShell.Header>
					<AppShell.Navbar p="md">
						<AppShell.Section>{navTitle}</AppShell.Section>
						<AppShell.Section grow my="md">
							<NavLink
								label={authLabel}
								href="/profile"
								leftSection={
									<IconKey
										style={{
											width: rem(16),
											height: rem(16),
										}}
										stroke={1.5}
									/>
								}
							></NavLink>
							<NavLink
								label="Plex Media Server"
								href="/"
								leftSection={
									<IconMovie
										style={{
											width: rem(16),
											height: rem(16),
										}}
										stroke={1.5}
									/>
								}
							></NavLink>
							<NavLink
								label="Micro Center Transactions"
								href="/transactions"
								leftSection={
									<IconCreditCardPay
										style={{
											width: rem(16),
											height: rem(16),
										}}
										stroke={1.5}
									/>
								}
							></NavLink>
							{role === "admin" && (
								<NavLink
									label="Plex Tools Page"
									href="/plex-tools"
									leftSection={
										<IconPhoto
											style={{
												width: rem(16),
												height: rem(16),
											}}
											stroke={1.5}
										/>
									}
								></NavLink>
							)}
						</AppShell.Section>
						<AppShell.Section>
							{token && (
								<Button
									label="Logout"
									variant="gradient"
									gradient={{
										from: "red.5",
										to: "red.9",
										deg: 45,
									}}
									onClick={handleLogout}
									mt={"auto"}
									leftSection={
										<IconLogout
											style={{
												width: rem(16),
												height: rem(16),
											}}
											stroke={1.5}
										/>
									}
								>
									Logout
								</Button>
							)}
						</AppShell.Section>
					</AppShell.Navbar>
					<AppShell.Main>
						<Routes>
							<Route path="/" element={<PlexPage />} />
							<Route path="/plex" element={<PlexPage />} />

							<Route path="/login" element={<LoginPage />} />
							<Route
								path="/reset-password"
								element={<PasswordResetPage />}
							/>

							<Route path="/profile" element={<ProfilePage />} />

							<Route
								path="/transactions"
								element={<TransactionsPage />}
							/>

							<Route
								path="/plex-tools"
								element={<PlexToolsPage />}
							/>

							<Route
								path="/web-site-down"
								element={<WebSiteDownPage />}
							/>

							{/* Catch all unmatched routes */}
							<Route path="/*" element={<Page404 />} />
						</Routes>
					</AppShell.Main>
				</AppShell>
			</AuthContext.Provider>
		</div>
	);
}

export default App;
