import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import YAML from "yaml";
import {
	Box,
	Button,
	Group,
	Text,
	Textarea,
	NativeSelect,
	Progress,
	NumberInput,
} from "@mantine/core";

import withAuth from "../services/user.service";
import { fetchFromAPI } from "../services/fetch.service";
import AuthService from "../services/auth.service";
import { checkDatabaseStatus } from "../services/fetch.service";

function PlexToolsPage({ currentUser, token }) {
	const navigate = useNavigate();

	// Default Page States
	const [pageHeading, setPageHeading] = useState("Plex Tools");
	const [submitButtonText, setSubmitButtonText] = useState("");
	const [showSubmitButton, setShowSubmitButton] = useState(false);
	const [selectedTool, setSelectedTool] = useState("");
	const toolList = [
		{ value: "", label: "Select a Tool" },
		{ value: "poster", label: "Poster Upload" },
		{ value: "plex_refresh", label: "Refresh Plex Metadata" },
	];
	const [textAreaLabel, setTextAreaLabel] = useState("");
	const [textAreaDescription, setTextAreaDescription] = useState("");
	const [textAreaPlaceholder, setTextAreaPlaceholder] = useState("");
	const [textAreaDisabled, setTextAreaDisabled] = useState(true);

	// Poster Upload States
	const [posterType, setPosterType] = useState("");
	const posterTypeList = [
		{ value: "", label: "Select a Media Type" },
		{ value: "series", label: "Series" },
		{ value: "archive", label: "Archive" },
	];
	const [archiveList, setArchiveList] = useState([]);
	const [selectedArchive, setSelectedArchive] = useState("");

	// Plex Refresh Metadata States
	const [days, setDays] = useState(1);

	// Progress Bar States
	const [progressValue, setProgressValue] = useState(0);
	const [progressColor, setProgressColor] = useState("black");
	const [progressText, setProgressText] = useState("");
	const [progressNextStep, setProgressNextStep] = useState("");
	const [progressAnimated, setProgressAnimated] = useState(true);

	useEffect(() => {
		const initialize = async () => {
			document.title = "MooseBoxx | Plex Tools";

			try {
				const databaseStatus = await checkDatabaseStatus();
				if (!databaseStatus) {
					navigate("/web-site-down");
					return;
				}

				const token = AuthService.getCookie("jwt");
				if (token) {
					const decodedToken = await AuthService.validateToken(token);
					if (!decodedToken) {
						navigate("/login");
					}
				}
			} catch (error) {
				handleError("Initialization failed: " + error.message);
			}
		};

		initialize();
	}, [navigate]);

	const form = useForm({
		initialValues: {
			textContents: "",
		},
		validate: {
			textContents: (value) => {
				if (selectedTool === "poster") {
					if (!value) {
						if (posterType === "series") {
							return "Series YAML Content is required";
						} else if (posterType === "archive") {
							return "Select an archive from the dropdown before proceeding";
						}
					} else if (value.length < 10) {
						return "YAML Content must be at least 10 characters";
					} else if (value) {
						let parsedYAML;
						// Check if the YAML contents are valid
						try {
							// Parse the YAML contents
							parsedYAML = YAML.parse(value);
						} catch (error) {
							return "Invalid YAML Content";
						}

						// YAML must start with a digit
						const firstChar = value.trim().charAt(0);
						if (isNaN(firstChar) || firstChar === "") {
							return "YAML must start with a digit";
						}

						// YAML must contain at least one of the following keys: url_poster, url_background
						const containsRequiredKeys = (obj) => {
							if (typeof obj !== "object" || obj === null)
								return false;
							if ("url_poster" in obj || "url_background" in obj)
								return true;
							return Object.values(obj).some(
								containsRequiredKeys
							);
						};

						if (!containsRequiredKeys(parsedYAML)) {
							return "YAML must contain at least one of the following keys: url_poster, url_background";
						}

						// YAML must contain at least one link to a poster or background
						// Ensure it starts with https
						const checkLinks = (obj) => {
							if (typeof obj !== "object" || obj === null)
								return false;
							if (
								("url_poster" in obj &&
									obj.url_poster.startsWith("https")) ||
								("url_background" in obj &&
									obj.url_background.startsWith("https"))
							)
								return true;
							return Object.values(obj).some(checkLinks);
						};

						if (!checkLinks(parsedYAML)) {
							return "YAML must contain at least one valid link (starting with https) to a poster or background";
						}
					}
				}
			},
		},
	});

	const handleFormSubmit = async (values) => {
		try {
			if (selectedTool === "poster") {
				// Steps:
				// 1 - Check if the form is valid
				// 2 - Create the YAML file on the server
				// 3 - Sort the URLs
				// 4 - Download the images sequentially
				// 5 - Move the files to the correct location
				// 6 - Refresh Plex

				// Step 1
				// Check if the form is valid
				setProgressValue(0);
				setProgressAnimated(true);
				setProgressColor("black");
				setProgressText("Validating form...");
				setProgressNextStep("");
				if (!form.isValid) {
					return; // Exit if the form is invalid
				}
				setProgressValue(10);
				setProgressColor("yellow");
				setProgressText("Form is valid.");

				// Step 2
				// Create the YAML file on the server
				setProgressNextStep("Creating YAML file...");
				const resp_create = await api_create_yaml(values);

				// Check if the YAML file was created successfully
				if (!resp_create || resp_create.status === "error") {
					throw new Error(
						resp_create.message || "Error creating YAML file"
					);
				}

				setProgressValue(20);
				setProgressColor("blue");
				setProgressText("YAML file created successfully");

				if (resp_create && resp_create.status === "success") {
					// Step 3
					// Sort the URLs
					setProgressNextStep("Sorting URLs...");
					const sortedUrls = sortUrls(resp_create.urls);

					// Check if the URLs were sorted successfully
					if (!sortedUrls) {
						throw new Error("Error sorting URLs");
					}

					setProgressValue(30);
					setProgressColor("purple");
					setProgressText("URLs sorted successfully");

					// Get the increment value for the progress bar
					const numUrls = sortedUrls.length;
					const incrementValue = Math.floor(50 / numUrls);

					// Step 4
					// Download the images sequentially
					setProgressNextStep("Downloading images...");
					await downloadImagesSequentially(
						sortedUrls,
						resp_create.filename,
						incrementValue
					);

					// Step 5
					// Move the files to the correct location
					setProgressText("Images downloaded successfully");
					setProgressNextStep(
						"Moving files to the correct location..."
					);
					setProgressValue(80);
					setProgressColor("cyan");

					const resp_move = await api_move_files(resp_create);

					// Check if the files were moved successfully
					if (!resp_move || resp_move.status === "error") {
						throw new Error(
							resp_move.message || "Error moving files"
						);
					}

					// Step 6
					// Refresh Plex
					setProgressValue(90);
					setProgressText("Files moved successfully");
					setProgressNextStep("Refreshing Plex...");

					const resp_refresh = await api_refresh_plex_images(
						resp_create
					);

					// Check if Plex was refreshed successfully
					if (!resp_refresh || resp_refresh.status === "error") {
						throw new Error(
							resp_refresh.message || "Error refreshing Plex"
						);
					}
					setProgressValue(100);
					setProgressColor("green");
					setProgressText("Plex refreshed successfully");
					setProgressNextStep("All done!");
					setProgressAnimated(false);
					form.setFieldValue("textContents", "");
				}
			} else if (selectedTool === "plex_refresh") {
				setProgressValue(0);
				setProgressAnimated(true);
				setProgressColor("cyan");
				setProgressText("Refreshing Plex...");
				setProgressNextStep("");
				form.setFieldValue("textContents", "");
				const resp_refresh = await api_refresh_plex_metadata();

				if (!resp_refresh || resp_refresh.status === "error") {
					throw new Error(
						resp_refresh.message || "Error refreshing Plex"
					);
				}
				setProgressValue(100);
				setProgressColor("green");
				setProgressText("Plex refreshed successfully");
				setProgressNextStep("All done!");
				setProgressAnimated(false);
				form.setFieldValue("textContents", resp_refresh.output);
			}
		} catch (error) {
			handleError(error.message);
		}
	};

	const sortUrls = (urls) => {
		try {
			const episodePattern = /^S\d+E\d+$/;
			return Object.entries(urls).sort(([keyA], [keyB]) => {
				const isEpisodeA = episodePattern.test(keyA);
				const isEpisodeB = episodePattern.test(keyB);

				if (isEpisodeA && isEpisodeB) {
					const [seasonA, episodeA] = keyA.match(/\d+/g).map(Number);
					const [seasonB, episodeB] = keyB.match(/\d+/g).map(Number);
					return seasonA === seasonB
						? episodeA - episodeB
						: seasonA - seasonB;
				}
				return keyA.localeCompare(keyB);
			});
		} catch (error) {
			throw error;
		}
	};

	const downloadImagesSequentially = async (
		sortedUrls,
		filename,
		incrementValue
	) => {
		for (const [name, url] of sortedUrls) {
			await api_download_image(name, url, filename);
			setProgressValue((prevValue) => prevValue + incrementValue);
		}
	};

	const api_create_yaml = async (values) => {
		setProgressNextStep("Creating YAML file...");
		setProgressColor("yellow");
		const { textContents } = values;
		if (!textContents) {
			throw new Error("YAML is empty");
		}

		const API_PATH = "/api/plex/poster/mediux/create";
		try {
			if (!token) {
				throw new Error("Authorization token is missing");
			}
			const body = JSON.stringify({
				body_data: textContents,
			});
			const resp = await fetchFromAPI(API_PATH, {
				method: "POST",
				body: body,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (resp.status === "success") {
				// Parse the URLs JSON string into an object
				resp.urls = JSON.parse(resp.urls);
				return resp;
			} else if (resp.status === "error") {
				throw new Error(resp.message || "Error creating YAML file");
			}
		} catch (error) {
			throw error;
		}
	};

	const api_download_image = async (name, url, filename) => {
		setProgressNextStep(`Downloading ${name}`);
		const API_PATH = "/api/plex/poster/mediux/download";
		try {
			const body = JSON.stringify({
				url: url,
				name: name,
				folder: filename,
			});
			const data = await fetchFromAPI(API_PATH, {
				method: "POST",
				body: body,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (data.status === "success") {
				return data;
			} else if (data.status === "error") {
				throw new Error(data.message || "Error downloading image");
			}
		} catch (error) {
			throw error;
		}
	};

	const api_move_files = async (resp) => {
		const API_PATH = "/api/plex/poster/mediux/move";
		try {
			const body = JSON.stringify({
				folder: resp.filename,
				hasSeries: resp.hasSeries,
				has4K: resp.has4K,
			});
			const data = await fetchFromAPI(API_PATH, {
				method: "POST",
				body: body,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (data) {
				return data;
			}
		} catch (error) {
			throw error;
		}
	};

	const api_refresh_plex_images = async (yaml_data) => {
		const API_PATH = "/api/plex/poster/mediux/refresh";
		try {
			const body = JSON.stringify({
				mediaName: yaml_data.showName,
				tvdbID: yaml_data.tvdbID,
				hasSeries: yaml_data.hasSeries,
				has4K: yaml_data.has4K,
			});
			const data = await fetchFromAPI(API_PATH, {
				method: "POST",
				body: body,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (data) {
				return data;
			}
		} catch (error) {
			throw error;
		}
	};

	const api_refresh_plex_metadata = async () => {
		const API_PATH = `/plex/refresh_metadata/${days}`;
		const controller = new AbortController();
		const timeoutId = setTimeout(() => controller.abort(), 600000); // 10 minutes

		let totalTime = days * 15; // total time in seconds
		let incrementTime = totalTime / 90; // time for each increment
		let increment = 90 / totalTime; // increment value
		let progressValue = 0; // initialize progressValue
		const intervalId = setInterval(() => {
			progressValue += increment;
			setProgressValue(progressValue);
			if (progressValue >= 90) {
				clearInterval(intervalId);
			}
		}, incrementTime * 1000);
		try {
			const data = await fetchFromAPI(API_PATH, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
				signal: controller.signal,
			});
			clearTimeout(timeoutId);
			clearInterval(intervalId);
			if (data) {
				return data;
			}
		} catch (error) {
			throw error;
		}
	};

	const handleError = (error) => {
		setProgressColor("red");
		setProgressText(error);
		setProgressNextStep("Check logs for more information and try again");
		setProgressAnimated(false);
	};

	const onChangeArchiveList = (value) => {
		const selectedArchive = archiveList.find((item) => item === value);
		setSelectedArchive(selectedArchive);
		if (selectedArchive) {
			api_get_archive(selectedArchive)
				.then((data) => {
					form.setFieldValue("textContents", data.yaml_contents);
					setProgressColor("green");
					setProgressText("Archive loaded successfully");
					setProgressNextStep("Click Re-Do Archive to re-create");
					setProgressValue(100);
					setProgressAnimated(false);
				})
				.catch((error) => {
					handleError(error.message);
				});
		} else {
			setSelectedArchive("");
			setProgressColor("black");
			setProgressText("");
			setProgressNextStep("");
			setProgressValue(0);
			setProgressAnimated(true);
			form.setFieldValue("textContents", "");
			return;
		}
	};

	const api_get_archive = async (filename) => {
		const API_PATH = "/api/plex/poster/mediux/get_archive";
		try {
			if (!token) {
				throw new Error("Authorization token is missing");
			}
			const body = JSON.stringify({
				filename: filename,
			});
			const data = await fetchFromAPI(API_PATH, {
				method: "POST",
				body: body,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (data.status === "success") {
				return data;
			} else {
				throw new Error(data.message || "Error getting archive");
			}
		} catch (error) {
			throw error;
		}
	};

	const api_get_archive_list = async () => {
		const API_PATH = "/api/plex/poster/mediux/get_archive_list";
		try {
			if (!token) {
				throw new Error("Authorization token is missing");
			}
			const data = await fetchFromAPI(API_PATH, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (data.status === "success") {
				// Parse the files string into an array
				data.files = JSON.parse(data.files);
				return data;
			} else {
				throw new Error(data.message || "Error getting archive list");
			}
		} catch (error) {
			throw error;
		}
	};

	const resetProgress = () => {
		setProgressValue(0);
		setProgressColor("black");
		setProgressText("");
		setProgressNextStep("");
		setProgressAnimated(true);
	};

	const resetPoster = () => {
		setPosterType("");
		setTextAreaDisabled(true);
		setTextAreaLabel("");
		setTextAreaPlaceholder("");
		setTextAreaDescription("");
		setSubmitButtonText("");
		setShowSubmitButton(false);
		setArchiveList([]);
		setSelectedArchive("");
		resetProgress();
		form.setFieldValue("textContents", "");
	};

	const onChangeSelectedTool = (value) => {
		setSelectedTool(value);
		if (value === "") {
			setPageHeading("Plex Tools");
			resetPoster();
		} else if (value === "poster") {
			setPageHeading("Plex Tools - Poster Upload");
		} else if (value === "plex_refresh") {
			setPageHeading("Plex Tools - Refresh Plex Metadata");
			resetPoster();
			setSubmitButtonText("Refresh Plex");
			setShowSubmitButton(true);
			setTextAreaDisabled(true);
			setTextAreaLabel("Plex Refresh Logs");
			setTextAreaPlaceholder("Logs will be displayed here");
			setTextAreaDescription("");
		}
	};

	const onChangePosterType = (value) => {
		const headings = {
			series: "Plex Tools - Series Poster Upload",
			archive: "Plex Tools - Archive Poster Upload",
		};
		setPageHeading(headings[value] || "Plex Tools - Poster Upload");
		setPosterType(value);

		if (value === "series") {
			setSubmitButtonText("Download Posters and Refresh Plex");
			setTextAreaDisabled(false);
			setTextAreaLabel("Series YAML Contents");
			setTextAreaPlaceholder("Enter in the Series YAML contents");
			setTextAreaDescription(
				<>
					Enter in the Series YAML contents from{" "}
					<a
						href="https://mediux.pro"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://mediux.pro
					</a>
				</>
			);
			setShowSubmitButton(true);
		} else if (value === "archive") {
			setSubmitButtonText("Re-Do Archive");
			setTextAreaDisabled(true);
			setTextAreaDescription("");
			setTextAreaLabel("Archived YAML Contents");
			setTextAreaPlaceholder("YAML contents will be loaded here");
			setShowSubmitButton(true);
			api_get_archive_list()
				.then((data) => {
					setArchiveList(data.files);
				})
				.catch((error) => {
					handleError(error.message);
				});
			form.setFieldValue("textContents", "");
			setSelectedArchive("");
		} else if (value === "") {
			setSubmitButtonText("");
			setShowSubmitButton(false);
		}
	};

	const onChangeDays = (value) => {
		setDays(value);
		form.setFieldValue("textContents", "");
	};

	return (
		<Box pb={50}>
			{/* If the user is an admin */}
			{currentUser && currentUser.role === "admin" ? (
				<Box mx={50}>
					<Group justify="center">
						<h1>{pageHeading}</h1>
					</Group>
					<form
						onSubmit={form.onSubmit(() =>
							handleFormSubmit(form.values)
						)}
					>
						<NativeSelect
							mb={30}
							value={selectedTool}
							label="Tool"
							onChange={(event) =>
								onChangeSelectedTool(event.currentTarget.value)
							}
							data={[
								...toolList.map((tool) => ({
									value: tool.value,
									label: tool.label,
								})),
							]}
						/>
						{
							// If the selected tool is Poster Upload
							selectedTool === "poster" ? (
								<NativeSelect
									value={posterType}
									label="Media Type"
									onChange={(event) =>
										onChangePosterType(
											event.currentTarget.value
										)
									}
									data={[
										...posterTypeList.map((type) => ({
											value: type.value,
											label: type.label,
										})),
									]}
								/>
							) : null
						}
						{posterType === "archive" ? (
							<NativeSelect
								value={selectedArchive}
								label="Archive List"
								onChange={(event) =>
									onChangeArchiveList(
										event.currentTarget.value
									)
								}
								// Get the list of files from the server
								data={[
									{ value: "", label: "Select an archive" },
									...archiveList.map((archive) => ({
										value: archive,
										label: archive,
									})),
								]}
							/>
						) : null}

						{selectedTool === "plex_refresh" ? (
							<NumberInput
								value={days}
								onChange={(value) => onChangeDays(value)}
								label="Days"
							/>
						) : null}
						{posterType === "series" ||
						posterType === "archive" ||
						selectedTool === "plex_refresh" ? (
							<Textarea
								label={textAreaLabel}
								withAsterisk
								description={textAreaDescription}
								placeholder={textAreaPlaceholder}
								autosize
								disabled={textAreaDisabled}
								minRows={2}
								{...form.getInputProps("textContents")}
							></Textarea>
						) : null}

						{showSubmitButton ? (
							<div>
								<Progress
									color={progressColor}
									radius="md"
									value={progressValue}
									striped
									animated={progressAnimated}
									mt="md"
								/>

								<Text mt="md">{progressText}</Text>
								<Text>{progressNextStep}</Text>
								<Group justify="center" mt="md">
									<Button type="submit" variant="gradient">
										{submitButtonText}
									</Button>
								</Group>
							</div>
						) : null}
					</form>
				</Box>
			) : (
				<Box>
					<Group justify="center">
						<h1>You must be an admin to access this page</h1>
					</Group>
				</Box>
			)}
		</Box>
	);
}

export default withAuth(PlexToolsPage);
