export const fetchFromAPI = async (endpoint, options) => {
	//const BASE_URL = process.env.REACT_APP_API_URL;
	const BASE_URL = "https://tools_api.mooseboxx.com";
	const url = `${BASE_URL}${endpoint}`;

	const response = await fetch(url, options);
	const data = await response.json();
	if (!response.ok) {
		return { status: data.status, message: data.message };
	} else {
		return data;
	}
};

export const checkDatabaseStatus = async () => {
	const API_PATH = "/health";
	try {
		const data = await fetchFromAPI(API_PATH, {
			method: "GET",
		});
		if (data.status === "success") {
			return true;
		}
	} catch (error) {
		return false;
	}
};
